<template>
  <v-dialog v-model="dialogDetail" persistent max-width="500px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                  {{
                    $vuetify.lang.t("$vuetify.payment2") +
                    " " +
                    $vuetify.lang.t("$vuetify.detail")
                  }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  :rules="generalRule"
                  :label="$vuetify.lang.t('$vuetify.cash')"
                  v-model="priceDetail.cash"
                  type="number"
                />
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  :rules="generalRule"
                  :label="$vuetify.lang.t('$vuetify.onlinePay')"
                  v-model="priceDetail.online"
                  type="number"
                />
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.other')"
                  v-model="priceDetail.other"
                  type="number"
                />
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-textarea
                  :label="$vuetify.lang.t('$vuetify.remarks')"
                  v-model="priceDetail.remarks"
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />

        <v-btn color="red darken-2" outlined @click="cancelForm">
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>

        <v-btn color="blue darken-2" @click="validate">
          {{ $vuetify.lang.t("$vuetify.confirm") }}
        </v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PaymentDetail",
  props: { dialogDetail: Boolean, priceDetail: Object },
  data() {
    return {
      valid: false,
      generalRule: [(v) => !!v || "This field is required"],
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialogDetail", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("update:priceDetail", this.priceDetail);
        this.$emit("update:dialogDetail", false);
      } else {
        console.log("false");
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>